<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/trade.png" alt />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png" alt />
        <span class="center">交易管理</span>
      </div>
    </div>
    <div class="flex_seach" style="width: 100%;">
      <div class="flex_seach">
        <div style="display: flex;align-items: baseline;width: 31%">
          <span class="seach_text" style="width: 28%">关键字：</span>
          <el-input placeholder="请输入付款账户、收款账户" v-model="formInline.keywords" size="mini" style="margin-right: 10px;"
            prefix-icon="el-icon-search" clearable></el-input>
        </div>
        <div style="margin: 0 10px;width: 31%">
          <span class="seach_text">交易类型：</span>
          <el-select :value="formInline.type" size="mini" clearable @change="stateChange">
            <el-option v-for="(item, index) in stateList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin: 0 10px;width: 40%">
          <span class="seach_text">审批状态：</span>
          <!-- -->
          <el-select :value="formInline.state" size="mini" clearable @change="approveStateChange">
            <el-option v-for="(item, index) in approveList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-button style="margin: 0 10px" type="primary" size="small" @click="seachBtn">查询</el-button>
        </div>
        <!-- <el-row :gutter="24">
          <el-col></el-col>
         
          <el-col></el-col>
    
          <el-col></el-col>
        </el-row>-->
      </div>
      <div>
        <el-button v-access data-ctrl="projectCheckCreate" @click="addLoan" size="small" type="primary"
          icon="el-icon-plus" style="margin-right: 10px">新建借款交易</el-button>
        <AddLoan :code="code" @close="close()" :loanhow.sync="loanhow"></AddLoan>
        <el-button v-access data-ctrl="addRepayment" style="margin-right: 10px" @click="addRepayment" size="small"
          type="primary" icon="el-icon-plus">新建还款交易</el-button>
        <Addrepayment :code="code" @close="close()" :repaymentShow.sync="repaymentShow"></Addrepayment>

        <el-button v-access data-ctrl="addCarryForward" @click="addCarryForward" size="small" type="primary"
          icon="el-icon-plus">新建结转交易</el-button>
        <AddcarryForward :code="code" @close="close()" :carryForwardShow.sync="carryForwardShow"></AddcarryForward>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%" align="center">
      <el-table-column label="
      交易编号">
        <template slot-scope="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      付款账户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.payAccountName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      收款账户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.payeeAccountName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      交易金额(￥)" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      交易时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | dateVal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      交易状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.tradeStatus === '3' && scope.row.type === 'carryOver'">结转失败</span>
          <span v-if="scope.row.tradeStatus === '3' && scope.row.type === 'payBack'">还款失败</span>
          <span v-if="scope.row.tradeStatus === '3' && scope.row.type === 'borrow'">借款失败</span>
          <span v-if="scope.row.tradeStatus === '0'">待执行</span>
          <span v-if="scope.row.tradeStatus === '1'">交易中</span>
          <span v-if="scope.row.tradeStatus === '2'">交易完成</span>
        </template>
      </el-table-column>
      <el-table-column label="
      状态描述" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.tradeStatusDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      交易备注" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column label="
      审批状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state === '0'" style="color: red" @click="stateDetail(scope.row)">未通过</span>
          <span v-if="scope.row.state === '2'" style="color: blue" @click="stateDetail(scope.row)">已完成</span>
          <span v-if="scope.row.state === '3'" style="color: blue" @click="stateDetail(scope.row)">作废</span>
          <span v-if="scope.row.state === '1'" style="color: blue" @click="stateDetail(scope.row)">已完成</span>
          <span v-if="scope.row.state === '-1'" style="color: blue" @click="stateDetail(scope.row)">审核中</span>
        </template>
      </el-table-column>
      <el-table-column label="
      交易类型" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 'carryOver'">结转</el-tag>
          <el-tag v-if="scope.row.type === 'payBack'">还款</el-tag>
          <el-tag v-if="scope.row.type === 'borrow'">借款</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="
      操作" align="center" width="200px">
        <template slot-scope="scope" style="display: flex">
          <el-button type="text" style="color: blue" size="mini"
            v-if="scope.row.reviewer.checkerCode === checkerCode && scope.row.state === '1'" @click="goPay(scope.row)">
            去支付</el-button>
          <el-button type="text" style="color: blue"
            v-if="scope.row.flow.map(it => it.checkerCode === checkerCode).includes(true) && scope.row.state === '-1' && scope.row.flow.filter(it => it.checkerCode === checkerCode)[0].state === '-1'"
            @click="examineList(scope.row)">审核</el-button>
          <el-button type="text" style="color: blue"
            v-if="scope.row.state != '2' && scope.row.state != '3' && scope.row.creatorCode === checkerCode && scope.row.tradeStatus === '0'"
            size="mini" @click="goEdit(scope.row)">撤销</el-button>
          <el-button type="text" style="color: blue" v-if="scope.row.state === '3'" size="mini"
            @click="getDel(scope.row)">查看撤销信息</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background :current-page.sync="pageNumber" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-size="pageSize" prev-text="上一页" next-text="下一页"
        layout="prev,pager, next,jumper" :total="total"></el-pagination>
    </div>
    <PayDialog :code.sync="code" @close="close()" :Item.sync="item" :title.sync="title" :payShow.sync="payShow">
    </PayDialog>
    <examineDialog :code.sync="code" :examineVisible.sync="examineVisible" @close="close()"></examineDialog>
    <detailDialog :code.sync="code" @close="close()" :title.sync="title" :loanDetail.sync="loanDetail"
      :dialogVisible.sync="dialogVisible" :state.sync="state"></detailDialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const projectCheckList = api()('project.check.list.json');
export const projectCheckCancle = api()('project.check.cancel.json');
export default {
  components: {
    AddLoan: () => import('./addLoan.vue'),
    Addrepayment: () => import('./addRepayment.vue'),
    // Divider: () => import('../../components/divider.vue'),
    AddcarryForward: () => import('./addcarryForward'),
    PayDialog: () => import('./payDialog.vue'),
    detailDialog: () => import('./detailDialog.vue'),
    examineDialog: () => import('./examineDialog.vue')
  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      examineVisible: false,
      total: 0,
      item: {},
      dialogVisible: false,
      loanhow: false,
      repaymentShow: false,
      carryForwardShow: false,
      payShow: false,
      state: '',
      title: '',
      isSend: false,
      checkerCode: '',
      sms: '',
      code: '',
      loanDetail: {},
      formInline: {
        keywords: '',
        type: '',
        state: '',
      },
      tableData: [],
      approveList: [
        {
          value: '',
          label: '全部',
        },

        {
          value: '-1',
          label: '审批中',
        },
        {
          value: '0',
          label: '未通过',
        },
        {
          value: '2',
          label: '已通过',
        },
      ],
      //  <span v-if="scope.row.state === '0'" style="color: red" @click="stateDetail(scope.row)">未通过</span>
      //   <span v-if="scope.row.state === '2'" style="color: blue" @click="stateDetail(scope.row)">已完成</span>
      //   <span v-if="scope.row.state === '3'" style="color: blue" @click="stateDetail(scope.row)">作废</span>
      //   <span v-if="scope.row.state === '1'" style="color: blue" @click="stateDetail(scope.row)">已完成</span>
      //   <span v-if="scope.row.state === '-1'" style="color: blue" @click="stateDetail(scope.row)">审核中</span>
      stateList: [
        {
          value: '',
          label: '全部',
        },

        {
          value: 'borrow',
          label: '借款',
        },
        {
          value: 'payBack',
          label: '还款',
        },
        {
          value: 'carryOver',
          label: '结转',
        },
      ],

    };
  },
  mounted () {
    this.loadData();
    console.log(this.promise_)
    // module.submit = function () {
    //   console.log(this.promise_, 11111);
    //   if (this.promise_.state() === 'pending') {
    //     this.promise_.abort()
    //   }
    //   // todo
    // }
    this.checkerCode = window.localStorage.getItem('code');
  },
  methods: {
    examineList (row) {
      this.code = row.code;
      this.examineVisible = true;
    },
    stateChange (val) {
      this.formInline.type = val;
    },
    approveStateChange (val) {
      this.formInline.state = val
    },
    seachBtn () {
      this.pageNumber = 1
      console.log(this.formInline);
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline
        // keywords: this.formInline.keywords,
      };
      projectCheckList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false;
        }
        this.tableData = res.list;

        this.total = res.total;
      });
    },
    close () {
      this.loanhow = false;
      this.repaymentShow = false;
      this.carryForwardShow = false;
      this.dialogVisible = false
      this.payShow = false;
      this.examineVisible = false;
      this.loadData();
    },

    getDel (row) {
      const text = row.cancelMsg
      this.$alert(`${text}`, '撤销信息 ', {
        confirmButtonText: '确定',
        callback: action => {
          console.log(action);
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
    },
    stateDetail (row) {

      if (row.type === 'borrow') {
        this.title = '借款审批';
      }
      else if (row.type === 'payBack') {
        this.title = '还款审批';
      }
      else {
        this.title = '结转审批';
      }

      this.state = row.state;
      this.loanDetail = row;
      this.code = row.code
      this.dialogVisible = true;
    },

    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },

    loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.formInline.keywords,
      };
      projectCheckList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false;
        }
        this.tableData = res.list;

        this.total = res.total;
      });
    },
    addLoan () {
      this.loanhow = true;
    },
    goPay (item) {
      this.code = item.code;
      this.item = item

      if (item.type === 'borrow') {
        this.title = '借款复核';
      }
      else if (item.type === 'payBack') {
        this.title = '还款复核';
      }
      else {
        this.title = '结转复核';
      }
      this.payShow = true;
    },
    goEdit (item) {
      if (item.type === 'borrow') {
        this.title = '借款';
      }
      else if (item.type === 'payBack') {
        this.title = '还款';
      }
      else {
        this.title = '结转';
      }
      this.$prompt('请输入备注', `确定撤销当前${this.title}交易？`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入备注',
      })
        .then(({ value }) => {

          const params = {
            code: item.code,
            cancelMsg: value,
          };
          projectCheckCancle(params).then((res) => {
            console.log(res, 111);
            this.loadData();
            this.$message({
              type: 'success',
              message: '撤销成功',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消',
          });
        });
    },
    addRepayment () {
      this.repaymentShow = true;
    },
    addCarryForward () {
      this.carryForwardShow = true;
    },
  },
};
</script>
<style lang="scss">
.el-table--border th.el-table__cell.gutter:last-of-type {
  border-bottom: 1px solid #ebeef5;
  border-bottom-width: 1px;
  display: none;
}

.flex_seach {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 10px 0;
}
</style>